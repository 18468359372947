<template>
  <v-container slot="pdf-content">
    <v-card class="pa-6">
      <v-card-title class="primary white--text rounded-0"><span>el/ka/sy® ON-SITE Effizienzrechner</span></v-card-title>
      <v-card-subtitle class="primary white--text"
        >Lassen Sie sich hier ihre Prozesskosteneinsparungen berechnen - optimiert durch el/ka/sy®</v-card-subtitle
      >
      <v-card-text class="mt-4">
        <v-row justify="space-around">
          <v-col m="12" lg="7" class="px-4 py-0">
            <VueHtml2pdf
              :show-layout="true"
              :float-layout="false"
              :enable-download="true"
              :preview-modal="false"
              :paginate-elements-by-height="700"
              :pdf-quality="2"
              :manual-pagination="true"
              pdf-content-width="100%"
              :html-to-pdf-options="html2PDFOptions"
              ref="html2Pdf"
            >
              <CalcTable slot="pdf-content" @calculated-event="fillResultDataEventHandler($event)"></CalcTable
            ></VueHtml2pdf>
          </v-col>
          <v-col m="12" lg="4" class="px-4 py-0">
            <ResultDisplay @download-button-pressed-event="generateReport()" :resultData="resultData"></ResultDisplay>
          </v-col> </v-row
      ></v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import ResultDisplay from "./resultDisplay/ResultDisplay.vue";
import CalcTable from "./calcTable/CalcTable.vue";

export default {
  components: { VueHtml2pdf, ResultDisplay, CalcTable },
  data() {
    return {
      resultData: {
        yearlyOverallCost: 0,
        yearlyOverallSavings: 0,
        yearlySavingsPotential: 0,
        overallCost: 0,
        overallSavings: 0,
        overallTimeExpense: 0,
        overallCostPerHour: 0,
      },
      html2PDFOptions: {
        margin: [-2, 10],
        filename: `Effizienzrechner.pdf`,
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        pagebreak: { mode: "css", before: ".pdf-page-before-break" },
        enableLinks: false,
        html2canvas: {
          scale: 2,
        },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "landscape",
        },
      },
    };
  },
  methods: {
    fillResultDataEventHandler(resultData) {
      this.resultData = resultData;
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>

<style>
.pdf-calc-table-elem {
  width: 1200px;
  height: 1000px;
}
.pdf-result-display-elem {
  width: 1200px;
  height: 1000px;
  position: absolute;
  top: 175%;
  right: 50%;
  transform: translate(50%, -65%);
}
</style>
