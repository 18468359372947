class ProcessStep {
    constructor(name, icon, costPerHour, timeExpense, averageSavings) {
      this.name = name;
      this.icon = icon;
      this.costPerHour = costPerHour;
      this.timeExpense = timeExpense;
      this.averageSavings = averageSavings;
      this.overallProcessCost = 0;
      this.actualSavings = 0;
    }

    calc() {
        this.overallProcessCost = 0;
        this.actualSavings = 0;
        this.calcCostPerHour();
        this.calcActualSavings();
    }

    calcCostPerHour(){
        if(!isNaN(this.timeExpense) && !isNaN(this.costPerHour) && this.timeExpense > 0 && this.costPerHour > 0){
            this.overallProcessCost = (this.costPerHour / 60) * this.timeExpense;
        }
    }

    calcActualSavings(){
        if(!isNaN(this.overallProcessCost) && !isNaN(this.averageSavings)){
            this.actualSavings = this.overallProcessCost * (this.averageSavings / 100)
        }
    }

  }


  export default ProcessStep;
  