export default {
    methods: {
    displayNumber: function(val, decPlaces, unit){
        let number = val.toFixed(decPlaces);
        let numberSplit = number.toString().split(".");
        let numberBeforeDec = numberSplit[0]
        let numberAfterDec = numberSplit[1]
        numberBeforeDec = numberBeforeDec.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        if(!isNaN(numberAfterDec)){
            return numberBeforeDec + "," + numberAfterDec + " " + String(unit);
        }

        return numberBeforeDec + " " + String(unit);
    }
    }
}
