<template>
  <div class="savings-chart-container">
    <doughnut-chart class="savings-chart" :chart-data="chartData" :options="chartOptions"></doughnut-chart>
    <div class="savings-counter text-center">
      <h3 class="text-center">Ihre</h3>
      <h3 class="text-center pb-1">Kostenreduktion:</h3>
      <counting-display :val="costReduction" unit="€"></counting-display>
    </div>
  </div>
</template>

<script>
import DoughnutChart from "./DoughnutChart.js";
import CountingDisplay from "./CountingDisplay.vue";

export default {
  components: {
    DoughnutChart,
    CountingDisplay,
  },
  props: ["costsAfterSavings", "costReduction"],
  data() {
    return {
      chartData: {
        labels: ["Gesamtkosten", "Einsparungen"],
        datasets: [
          {
            data: [0, 0],
            backgroundColor: ["rgb(113, 23, 76)", "rgb(154,154,154"],
            borderWidth: 0,
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 1000,
        },
        rotation: 0.5 * Math.PI,
        legend: {
          display: false,
        },
      },
    };
  },
  mounted() {
    this.fillChart(this.costsAfterSavings, this.costReduction);
  },
  methods: {
    fillChart(finalCost, reducedCost) {
      this.chartData = {
        labels: ["Gesamtkosten", "Einsparungen"],
        datasets: [
          {
            data: [finalCost.toFixed(2), reducedCost.toFixed(2)],
            backgroundColor: ["rgb(125, 2, 79)", "rgb(154,154,154"],
            borderWidth: 0,
          },
        ],
      };
    },
  },
  watch: {
    costsAfterSavings: function () {
      this.fillChart(this.costsAfterSavings, this.costReduction);
    },
    costReduction: function () {
      this.fillChart(this.costsAfterSavings, this.costReduction);
    },
  },
};
</script>

<style>
.savings-chart {
  height: 300px;
  position: relative;
}

.savings-chart-container {
  position: relative;
}

.savings-counter {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -65%);
}
</style>
