<template>
  <v-app>
    <v-main>
      <div class="background"><EfficiencyCalculator /></div>
    </v-main>
  </v-app>
</template>

<script>
import EfficiencyCalculator from "./components/EfficiencyCalculator.vue";

export default {
  name: "App",

  components: {
    EfficiencyCalculator,
  },

  data: () => ({
    //
  }),
};
</script>

<style scoped>
.background {
  background-color: #f5f7fa;
  height: 100%;
}
</style>
